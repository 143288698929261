@font-face {
    font-family: "Product Sans";
    src: url("fonts/Product Sans Bold.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "ProspectusProXL";
    src: url("fonts/ProspectusProXLDEMO-Black.woff2") format("woff2"),
        url("fonts/ProspectusProXLDEMO-Black.woff") format("woff");
    font-weight: bolder;
    font-style: normal;
}
@font-face {
    font-family: "ProspectusProXL";
    src: url("fonts/ProspectusProXLDEMO-BlackItalic.woff2") format("woff2"),
        url("fonts/ProspectusProXLDEMO-BlackItalic.woff") format("woff");
    font-weight: bolder;
    font-style: italic;
}
@font-face {
    font-family: "ProspectusProXL";
    src: url("fonts/ProspectusProXLDEMO-Regular.woff2") format("woff2"),
        url("fonts/ProspectusProXLDEMO-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "ProspectusProXL";
    src: url("fonts/ProspectusProXLDEMO-Italic.woff2") format("woff2"),
        url("fonts/ProspectusProXLDEMO-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: "ProspectusProXL";
    src: url("fonts/ProspectusProXLDEMO-Bold.woff2") format("woff2"),
        url("fonts/ProspectusProXLDEMO-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "ProspectusProXL";
    src: url("fonts/ProspectusProXLDEMO-BoldItalic.woff2") format("woff2"),
        url("fonts/ProspectusProXLDEMO-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: "ProspectusProL";
    src: url("fonts/ProspectusProLDEMO-BlackItalic.woff2") format("woff2"),
        url("fonts/ProspectusProLDEMO-BlackItalic.woff") format("woff");
    font-weight: bolder;
    font-style: italic;
}
@font-face {
    font-family: "ProspectusProL";
    src: url("fonts/ProspectusProLDEMO-Black.woff2") format("woff2"),
        url("fonts/ProspectusProLDEMO-Black.woff") format("woff");
    font-weight: bolder;
    font-style: normal;
}
@font-face {
    font-family: "ProspectusProL";
    src: url("fonts/ProspectusProLDEMO-BoldItalic.woff2") format("woff2"),
        url("fonts/ProspectusProLDEMO-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: "ProspectusProL";
    src: url("fonts/ProspectusProLDEMO-Bold.woff2") format("woff2"),
        url("fonts/ProspectusProLDEMO-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "ProspectusProL";
    src: url("fonts/ProspectusProLDEMO-Italic.woff2") format("woff2"),
        url("fonts/ProspectusProLDEMO-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: "ProspectusProL";
    src: url("fonts/ProspectusProLDEMO-Regular.woff2") format("woff2"),
        url("fonts/ProspectusProLDEMO-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "ProspectusProM";
    src: url("fonts/ProspectusProMDEMO-BlackItalic.woff2") format("woff2"),
        url("fonts/ProspectusProMDEMO-BlackItalic.woff") format("woff");
    font-weight: bolder;
    font-style: italic;
}
@font-face {
    font-family: "ProspectusProM";
    src: url("fonts/ProspectusProMDEMO-Black.woff2") format("woff2"),
        url("fonts/ProspectusProMDEMO-Black.woff") format("woff");
    font-weight: bolder;
    font-style: normal;
}
@font-face {
    font-family: "ProspectusProM";
    src: url("fonts/ProspectusProMDEMO-BoldItalic.woff2") format("woff2"),
        url("fonts/ProspectusProMDEMO-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: "ProspectusProM";
    src: url("fonts/ProspectusProMDEMO-Bold.woff2") format("woff2"),
        url("fonts/ProspectusProMDEMO-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "ProspectusProM";
    src: url("fonts/ProspectusProMDEMO-Italic.woff2") format("woff2"),
        url("fonts/ProspectusProMDEMO-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: "ProspectusProM";
    src: url("fonts/ProspectusProMDEMO-Regular.woff2") format("woff2"),
        url("fonts/ProspectusProMDEMO-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "ProspectusProM";
    src: url("fonts/ProspectusProMDEMO-LightItalic.woff2") format("woff2"),
        url("fonts/ProspectusProMDEMO-LightItalic.woff") format("woff");
    font-weight: lighter;
    font-style: italic;
}
@font-face {
    font-family: "ProspectusProM";
    src: url("fonts/ProspectusProMDEMO-Light.woff2") format("woff2"),
        url("fonts/ProspectusProMDEMO-Light.woff") format("woff");
    font-weight: lighter;
    font-style: normal;
}
@font-face {
    font-family: "ProspectusProS";
    src: url("fonts/ProspectusProSDEMO-Regular.woff2") format("woff2"),
        url("fonts/ProspectusProSDEMO-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "ProspectusProS";
    src: url("fonts/ProspectusProSDEMO-Italic.woff2") format("woff2"),
        url("fonts/ProspectusProSDEMO-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

* {
    -webkit-transition: background-color 0.05s ease;
    transition: background-color 0.05s ease;
    transition: color 0.05s ease;
}

html,
body,
#root,
.app,
.content {
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: fit-content;
    margin: 0px;
    padding: 0px;
    scroll-behavior: smooth;
    font-family: sans-serif;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #cccba277;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #99987a77;
}
::-webkit-scrollbar-corner {
    background: transparent;
}
.loading-backdrop {
    animation-name: fade;
    animation-iteration-count: infinite;
    animation-duration: 2s;

    position: absolute;
    background-color: white;
    mix-blend-mode: overlay;
    width: 100%;
    height: 860px;
}
@keyframes fade {
    0% {
        opacity: 70%;
    }
    50% {
        opacity: 40%;
    }
    100% {
        opacity: 70%;
    }
}

/* Loading spinner button */
#lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
#lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #000000;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
#lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
#lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
#lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
#lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
